export const plan1 = [
    " Cremación Colectiva De La Mascota",
    "Porta Retrato De 20x25 cm",
    "Huella Plasmada En Arcilla De La Mascota",
    "Carta De Agradecimiento",
    "Un Vaso Mug De La Mascota o Planta Natural",
    "Dos Recordatorios De La Mascota",
  ];

 export const plan2 = [
    "Cremación Individual De La Mascota",
    "Cofre Cenizario De La Mascota",
    "Certificado De Cremación",
    "Porta Retrato De 20x25 cm",
    "Huella Plasmada En Arcilla De La Mascota",
    "Carta De Agradecimiento",
    "Un Vaso Mug De La Mascota",
    "Cuatro Recordatorios De La Mascota",
    "Una Planta Natural",
  ];
 export const plan3 = [
    "Podra Elegir Cualquier Cremación",
    "El Plan Podras Armarlo Como Lo Necesites",
    "Nos Acomodamos A Tus Necesidades",
  ];
  
 
  // export const Number = "57 3007256149";
  // export const whatsappNumber = "573007256149";
  export const Number = "57 3234035961";
  export const whatsappLink = 'https://wa.me/573234035961';
